import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as AdaptiveCards from 'adaptivecards';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

function ValidateEmail(inputText){
  const mailformat = /^\w+([.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return inputText.match(mailformat);
}


function CaseById() {
  const navigate = useNavigate();
  const { caseId } = useParams();
  const [payload, setPayload] = useState({});
  const [error, setError] = useState(null);
  const cardWrapperRef = useRef();

  useEffect(() => {
    async function getCardByCaseId(id) {
      // checking for view only
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      console.log('urlParams', urlParams);
      const viewOnly = urlParams.get('viewOnly') || 'F';

      const controller = new AbortController();
      try {
        let response = await fetch(`${API_BASE_URL}/api/page/case/${id}?viewOnly=${viewOnly}`, controller.signal);
        let responseJSON = await response.json();
        if (response.ok) {
          setPayload(responseJSON);
        } else {
          setError(responseJSON);
        }
      } catch (error) {
        setError(error);
        console.error(error);
      } finally {
        controller.abort();
      }
    }
    if (caseId) {
      getCardByCaseId(caseId);
    }
  }, [caseId]);

  useEffect(() => {
    if (!cardWrapperRef || !payload) return;

    const adaptiveCard = new AdaptiveCards.AdaptiveCard();
    adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
      fontFamily: 'Segoe UI, Helvetica Neue, sans-serif',
    });
    adaptiveCard.parse(payload);
    adaptiveCard.onExecuteAction = async function (action) {
      const { data } = action;
      if(action instanceof AdaptiveCards.OpenUrlAction) {
        console.log(action.headers);
        navigate(`/cases/upload/${caseId}`);
        return;
      }
      console.log('action.type', typeof action);
      let actionPerformer = prompt('Please enter Valid Email');
      if (actionPerformer === null) {
        console.log('action cancelled');
        return;
      }
      if(!ValidateEmail(actionPerformer)){
        alert('Please enter a valid email address');
        return;
      }

      const controller = new AbortController();
      let response;
      let responseJSON;
      try {
        response = await fetch(`${API_BASE_URL}/api/page/case/${caseId}?actionPerformer=${actionPerformer}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            signal: controller.signal,
          },
          body: JSON.stringify(data),
        });
        responseJSON = await response.json();
      } catch (error) {
        console.error(error);
        setError(error);
      } 
      
      if(response?.ok){
        if(responseJSON.status === 'OK'){
          navigate('/cases/submitted');
        }else{
          setError(responseJSON);
        }
        
      }else{
        let test = await response.text();
        console.log('test', test);
        let customError = Error(test);
        setError(customError);
      }
    };
    cardWrapperRef.current.innerHTML = '';
    adaptiveCard.render(cardWrapperRef.current);
  }, [cardWrapperRef, caseId, navigate, payload]);

  return (
    <main>
      {error && (
        <span className="alert">
          <span className="alert-message">
            <p>{error?.message}</p>
          </span>
        </span>
      )}
      <span ref={cardWrapperRef} />
    </main>
  );
}

export default CaseById;
