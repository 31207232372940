import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as AdaptiveCards from 'adaptivecards';
import { Formik, Form } from 'formik';
import Dropzone from '../../components/dropzone/Dropzone';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';

function ValidateEmail(inputText){
  const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return inputText.match(mailformat);
}


function CaseByIdUpload() {
  const navigate = useNavigate();
  const { caseId } = useParams();
  const [payload, setPayload] = useState(null);
  const [error, setError] = useState(null);
  const [uploader, setUploader] = useState(false);
  const cardWrapperRef = useRef();

  async function handleFileSubmit(values){
    console.log('values', values);

    const formData = new FormData();
    formData.append('actionPerformer', values.actionPerformer); 
    for (let i = 0; i < values.files.length; i++) {
      if (values.files[i].path) {
        formData.append(`file${i}`, values.files[i]);
      } else if (values.files[i].arrayBuffer && values.files[i].name) {
        formData.append(`file${i}`, new Blob([values.files[i].arrayBuffer]),  values.files[i].name );
      }
    }

    const controller = new AbortController();
    try {
      let response = await fetch(
        `${API_BASE_URL}/api/page/case/upload/${caseId}`,
        {
          body: formData,
          method: 'POST'
        },
        controller.signal);
      let responseJSON = await response.json();
      if(responseJSON.status === 'OK'){
        navigate('/cases/submitted');
      } else {
        setError(responseJSON);
      }
    } catch (error) {
      console.error(error);
      setError({message: 'There was Error uploading file, please try again later'});
    } finally {
      controller.abort();
    }
  }

  useEffect(() => {
    async function getCardByCaseId(id) {
      const controller = new AbortController();
      try {
        let response = await fetch(`${API_BASE_URL}/api/page/case/info/${id}`, controller.signal);
        let responseJSON = await response.json();
        console.log('responseJSON', responseJSON);
        if (response.ok) {
          setPayload(responseJSON);
        } else {
          setError(responseJSON);
        }
      } catch (error) {
        console.log(error);
        setError({message: 'INVALID CARD'});
      } finally {
        controller.abort();
      }
    }
    if (caseId) {
      getCardByCaseId(caseId);
    }
  }, [caseId]);

  useEffect(() => {
    console.log('payload', payload);
    if (!cardWrapperRef || !payload) return;
    
    const payloadCard = payload.card;

    const adaptiveCard = new AdaptiveCards.AdaptiveCard();
    adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
      fontFamily: 'Segoe UI, Helvetica Neue, sans-serif',
    });
    adaptiveCard.parse(payloadCard);
    cardWrapperRef.current.innerHTML = '';
    adaptiveCard.render(cardWrapperRef.current);
    if (payload.status === 'OK') {
      setUploader(true);
    }
  }, [cardWrapperRef, caseId, navigate, payload]);

  return (
    <main>
      {error && (
        <span className="alert">
          <span className="alert-message">
            <p>{error?.message}</p>
          </span>
        </span>
      )}
      <span ref={cardWrapperRef} />
      <br/>
      <div>
        { uploader ? (
          <Formik
            initialValues={{
              caseId,
              files: [],
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              let actionPerformer = prompt('Please enter Valid Email');
              if(!ValidateEmail(actionPerformer)){
                alert('Please enter a valid email address');
                return;
              }
              console.log('actionPerformer', actionPerformer);
              values.actionPerformer = actionPerformer;
              handleFileSubmit(values);
              setSubmitting(false);
            }}
          >
            {({ setFieldValue, values }) => (
              <Form className="SupportForm-form">
                <Dropzone
                  filesToUpload={values.files}
                  setFiles={(acceptedFiles) => {
                    setFieldValue('files', acceptedFiles);
                  }}
                />
                <div className="SupportForm-form-bottom">
                  <button className="submit-btn" type="submit">
                    submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (<div></div>)}
      </div>
    </main>
  );
}

export default CaseByIdUpload;
